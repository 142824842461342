/* eslint-disable import/no-anonymous-default-export */
import aktuell_1 from "./aktuell_1.jpg";
import aktuell_2 from "./aktuell_2.jpg";
import aktuell_3 from "./aktuell_3.jpg";
import aktuell_4 from "./aktuell_4.jpg";
import aktuell_5 from "./aktuell_5.jpg";
import aktuell_6 from "./aktuell_6.jpg";
import aktuell_7 from "./aktuell_7.jpg";
import aktuell_8 from "./aktuell_8.jpg";
import aktuell_9 from "./aktuell_9.jpg";
import aktuell_10 from "./aktuell_10.jpg";
import aktuell_11 from "./aktuell_11.jpg";
import aktuell_12 from "./aktuell_12.jpg";
import aktuell_13 from "./aktuell_13.jpg";
import aktuell_14 from "./aktuell_14.jpg";
import aktuell_15 from "./aktuell_15.jpg";

export default [
  aktuell_1,
  aktuell_2,
  aktuell_3,
  aktuell_4,
  aktuell_5,
  aktuell_6,
  aktuell_7,
  aktuell_8,
  aktuell_9,
  aktuell_10,
  aktuell_11,
  aktuell_12,
  aktuell_13,
  aktuell_14,
  aktuell_15,
];
