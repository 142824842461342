/* eslint-disable import/no-anonymous-default-export */
import allgemein_1 from "./allgemein_1.jpg";
import allgemein_2 from "./allgemein_2.jpg";
import allgemein_3 from "./allgemein_3.jpg";
import allgemein_4 from "./allgemein_4.jpg";
import allgemein_5 from "./allgemein_5.jpg";
import allgemein_6 from "./allgemein_6.jpg";
import allgemein_7 from "./allgemein_7.jpg";
import allgemein_8 from "./allgemein_8.jpg";
import allgemein_9 from "./allgemein_9.jpg";
import allgemein_10 from "./allgemein_10.jpg";
import allgemein_11 from "./allgemein_11.jpg";
import allgemein_12 from "./allgemein_12.jpg";
import allgemein_13 from "./allgemein_13.jpg";
import allgemein_14 from "./allgemein_14.jpg";
import allgemein_15 from "./allgemein_15.jpg";
import allgemein_16 from "./allgemein_16.jpg";
import allgemein_17 from "./allgemein_17.jpg";
import allgemein_18 from "./allgemein_18.jpg";
import allgemein_19 from "./allgemein_19.jpg";
import allgemein_20 from "./allgemein_20.jpg";
import allgemein_21 from "./allgemein_21.jpg";
import allgemein_22 from "./allgemein_22.jpg";
import allgemein_23 from "./allgemein_23.jpg";
import allgemein_24 from "./allgemein_24.jpg";
import allgemein_25 from "./allgemein_25.jpg";
import allgemein_26 from "./allgemein_26.jpg";
import allgemein_27 from "./allgemein_27.jpg";
import allgemein_28 from "./allgemein_28.jpg";
import allgemein_29 from "./allgemein_29.jpg";
import allgemein_30 from "./allgemein_30.jpg";
import allgemein_31 from "./allgemein_31.jpg";
import allgemein_32 from "./allgemein_32.jpg";
import allgemein_33 from "./allgemein_33.jpg";
import allgemein_34 from "./allgemein_34.jpg";
import allgemein_35 from "./allgemein_35.jpg";
import allgemein_36 from "./allgemein_36.jpg";
import allgemein_37 from "./allgemein_37.jpg";
import allgemein_38 from "./allgemein_38.jpg";
import allgemein_39 from "./allgemein_39.jpg";
import allgemein_40 from "./allgemein_40.jpg";
import allgemein_41 from "./allgemein_41.jpg";
import allgemein_42 from "./allgemein_42.jpg";
import allgemein_43 from "./allgemein_43.jpg";
import allgemein_44 from "./allgemein_44.jpg";
import allgemein_45 from "./allgemein_45.jpg";
import allgemein_46 from "./allgemein_46.jpg";
import allgemein_47 from "./allgemein_47.jpg";
import allgemein_48 from "./allgemein_48.jpg";
import allgemein_49 from "./allgemein_49.jpg";
import allgemein_50 from "./allgemein_50.jpg";
import allgemein_51 from "./allgemein_51.jpg";
import allgemein_52 from "./allgemein_52.jpg";
import allgemein_53 from "./allgemein_53.jpg";
import allgemein_54 from "./allgemein_54.jpg";
import allgemein_55 from "./allgemein_55.jpg";
import allgemein_56 from "./allgemein_56.jpg";
import allgemein_57 from "./allgemein_57.jpg";
import allgemein_58 from "./allgemein_58.jpg";
import allgemein_59 from "./allgemein_59.jpg";
import allgemein_60 from "./allgemein_60.jpg";
import allgemein_61 from "./allgemein_61.jpg";

export default [
  allgemein_1,
  allgemein_2,
  allgemein_3,
  allgemein_4,
  allgemein_5,
  allgemein_6,
  allgemein_7,
  allgemein_8,
  allgemein_9,
  allgemein_10,
  allgemein_11,
  allgemein_12,
  allgemein_13,
  allgemein_14,
  allgemein_15,
  allgemein_16,
  allgemein_17,
  allgemein_18,
  allgemein_19,
  allgemein_20,
  allgemein_21,
  allgemein_22,
  allgemein_23,
  allgemein_24,
  allgemein_25,
  allgemein_26,
  allgemein_27,
  allgemein_28,
  allgemein_29,
  allgemein_30,
  allgemein_31,
  allgemein_32,
  allgemein_33,
  allgemein_34,
  allgemein_35,
  allgemein_36,
  allgemein_37,
  allgemein_38,
  allgemein_39,
  allgemein_40,
  allgemein_41,
  allgemein_42,
  allgemein_43,
  allgemein_44,
  allgemein_45,
  allgemein_46,
  allgemein_47,
  allgemein_48,
  allgemein_49,
  allgemein_50,
  allgemein_51,
  allgemein_52,
  allgemein_53,
  allgemein_54,
  allgemein_55,
  allgemein_56,
  allgemein_57,
  allgemein_58,
  allgemein_59,
  allgemein_60,
  allgemein_61,
];
