/* eslint-disable import/no-anonymous-default-export */
import tierfreunde_1 from "./tierfreunde_1.jpg";
import tierfreunde_2 from "./tierfreunde_2.jpg";
import tierfreunde_3 from "./tierfreunde_3.jpg";
import tierfreunde_4 from "./tierfreunde_4.jpg";
import tierfreunde_5 from "./tierfreunde_5.jpg";
import tierfreunde_6 from "./tierfreunde_6.jpg";
import tierfreunde_7 from "./tierfreunde_7.jpg";
import tierfreunde_8 from "./tierfreunde_8.jpg";
import tierfreunde_9 from "./tierfreunde_9.jpg";
import tierfreunde_10 from "./tierfreunde_10.jpg";
import tierfreunde_11 from "./tierfreunde_11.jpg";
import tierfreunde_12 from "./tierfreunde_12.jpg";
import tierfreunde_13 from "./tierfreunde_13.jpg";
import tierfreunde_14 from "./tierfreunde_14.jpg";
import tierfreunde_15 from "./tierfreunde_15.jpg";
import tierfreunde_16 from "./tierfreunde_16.jpg";
import tierfreunde_17 from "./tierfreunde_17.jpg";
import tierfreunde_18 from "./tierfreunde_18.jpg";
import tierfreunde_19 from "./tierfreunde_19.jpg";
import tierfreunde_20 from "./tierfreunde_20.jpg";
import tierfreunde_21 from "./tierfreunde_21.jpg";
import tierfreunde_22 from "./tierfreunde_22.jpg";
import tierfreunde_23 from "./tierfreunde_23.jpg";

export default [
  tierfreunde_1,
  tierfreunde_2,
  tierfreunde_3,
  tierfreunde_4,
  tierfreunde_5,
  tierfreunde_6,
  tierfreunde_7,
  tierfreunde_8,
  tierfreunde_9,
  tierfreunde_10,
  tierfreunde_11,
  tierfreunde_12,
  tierfreunde_13,
  tierfreunde_14,
  tierfreunde_15,
  tierfreunde_16,
  tierfreunde_17,
  tierfreunde_18,
  tierfreunde_19,
  tierfreunde_20,
  tierfreunde_21,
  tierfreunde_22,
  tierfreunde_23,
];
